<template>
  <div class="input-data">
    <h2 class="is-size-5 has-text-weight-bold has-text-dark mb-4">{{ $t('feed_tray.feed_tray') }}</h2>

    <input-feed-tray-bulk/>
  </div>
</template>

<script>
import InputFeedTrayBulk from "@/components/Farm/FeedTray/InputFeedTrayBulk";

export default {
  name: "InputFeedTrayForm",
  components: {
    InputFeedTrayBulk
  },
  computed: {
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    embed () {
      return this.$route.query.output === 'embed'
    },
  },
}
</script>
