<template>
  <div class="input-data">
    <div>
      <section>

        <div class="is-flex is-align-items-start is-justify-content-space-between is-flex-wrap-wrap">
          <div class="is-flex is-flex-wrap-wrap">
            <sg-select :items="[{block_name: $t('all'), prepend: false},...selectedFarm.blocks]"
                       type="is-secondary is-light"
                       :prepend="$t('farm.block')"
                       class="mb-2 mr-2"
                       label="block_name"
                       v-model="selectedBlock"
                       :placeholder="`${$t('select')} ${$t('farm.block')}`"/>

            <b-datepicker
                class="mb-2 mr-2"
                :placeholder="$t('date')"
                icon="calendar-text"
                locale="id-ID"
                v-model="selectedDate"
                editable/>
          </div>

          <b-button type="is-text" class="has-text-info has-shadow mb-2" @click="resetForm()" icon-left="sync">
            Reset Form
          </b-button>
        </div>

        <div class="b-table custom-table" v-if="filteredData.length">
          <div class="table-wrapper has-sticky-header" :class="{embed: embed}">
            <table class="table is-bordered">
              <thead>
              <tr class="header-row">
                <th class="has-text-centered" rowspan="2">{{ $t('farm.pond') }}</th>
                <th :colspan="totalAnco" class="p-0" v-for="n in times.length" :key="n">
                  <b-field class="mb-0">
                    <p class="control w-100">
                      <b-timepicker
                          :placeholder="$t('time')"
                          icon="calendar-text"
                          locale="id-ID"
                          append-to-body
                          v-model="times[n - 1]"
                          editable/>
                    </p>
                    <p class="control">
                      <b-button type="is-primary is-light"
                                slot="trigger"
                                icon-left="minus"
                                @click="deleteData(n -1)">
                      </b-button>
                    </p>
                    <p class="control">
                      <b-button type="is-primary is-light"
                                class="is-square"
                                slot="trigger"
                                icon-left="plus"
                                @click="addData(n -1)">
                      </b-button>
                    </p>
                  </b-field>
                </th>
              </tr>
              <tr>
                <th class="has-text-centered" v-for="n in totalAnco * times.length" :key="n">{{ $t('feed_tray.feed_tray') }} {{ ((n - 1) % totalAnco) + 1 }}</th>
              </tr>
              </thead>
              <tbody v-if="canAddAnco">
              <ValidationObserver ref="form-input" tag="tr" v-for="(item, key) in filteredData" :key="key">
                <td class="has-text-centered">
                  {{ item.pond.name }}
                </td>
                <td v-for="n in totalAnco * times.length" :key="n" class="p-0 has-background-light">
                  <b-field class="m-0">
                    <b-select placeholder="Input Data"
                              ref="input-form" class="input-form"
                              @focus.prevent="handleFocus((key * maxInputForm) + (n - 1))"
                              @keydown.prevent.native.right="handleArrowRight"
                              @keydown.prevent.native.left="handleArrowLeft"
                              @keydown.prevent.native.down="handleArrowDown"
                              @keydown.prevent.native.up="handleArrowUp"
                              style="min-width: 150px"
                              v-if="!((n - 1) % totalAnco >= item.pond.anco)"
                              v-model="item.data_anco[Math.ceil(n / totalAnco) - 1][(n - 1) % totalAnco]">
                      <option value="Habis">{{ $t('feed_tray.Habis') }}</option>
                      <option value="Sisa Sedikit">{{ $t('feed_tray.Sisa Sedikit') }}</option>
                      <option value="Sisa Banyak">{{ $t('feed_tray.Sisa Banyak') }}</option>
                    </b-select>
                    <b-input placeholder="-"
                             ref="input-form" class="input-form"
                             @focus.prevent="handleFocus((key * maxInputForm) + (n - 1))"
                             @keydown.prevent.native.right="handleArrowRight"
                             @keydown.prevent.native.left="handleArrowLeft"
                             @keydown.prevent.native.down="handleArrowDown"
                             @keydown.prevent.native.up="handleArrowUp"
                             style="min-width: 150px" readonly v-else >
                    </b-input>
                  </b-field>
                </td>
              </ValidationObserver>
              </tbody>
            </table>
          </div>
        </div>
        <p class="has-text-danger" v-else>No Ponds have been stocked yet <span
            v-if="selectedBlock && selectedBlock.block_id">on Block {{ selectedBlock.block_name }}</span></p>
      </section>

      <footer class="is-flex is-justify-content-flex-end mt-3" v-if="filteredData.length">
        <b-button type="is-primary is-gradient" class="ml-2" @click="handleSubmit">{{ $t('save') }}</b-button>
      </footer>
    </div>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";

export default {
  name: "InputFeedTrayBulk",
  components: {SgSelect},
  computed: {
    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    canAddAnco() {
      return this.$store.getters["farm/canAddAnco"]
    },
    canEditAnco() {
      return this.$store.getters["farm/canEditAnco"]
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    filteredPonds() {
      if (this.selectedBlock && this.selectedFarm.ponds) return this.selectedFarm.ponds.filter(e => e.block_id === this.selectedBlock.block_id)
      else return []
    },
    filteredPondsInput() {
      return this.selectedFarm.ponds.filter(e => e.status >= 2)
    },
    totalAnco() {
      return this.data.length ? this.data.filter(e => e.pond).reduce((a, b) => b.pond.anco > a ? b.pond.anco : a, 0) : 0
    },
    filteredData() {
      let data = this.data.filter(e => e.pond.status >= 2)
      if (this.selectedBlock && this.selectedBlock.block_id) data = data.filter(e => e.pond.block_id === this.selectedBlock.block_id && e.pond.status >= 2)
      return data
    },
    embed() {
      return this.$route.query.output === 'embed'
    },
    maxInputForm() {
      return this.totalAnco * this.times.length
    },
  },
  data: () => {
    return {
      selectedDate: new Date(),
      times: [],
      selectedBlock: null,
      data: [],
      ancoOptions: ["Habis", "Sisa Sedikit", "Sisa Banyak"],

      selectedInput: 0,
      maxInput: 0,
    }
  },
  watch: {
    'selectedFarm.blocks'(value) {
      this.selectedBlock = value[0]
    },
    'selectedFarm.ponds'() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    handleFocus(n) {
      this.selectedInput = n
      this.maxInput = this.$refs[`input-form`].length - 1
    },

    handleArrowRight() {
      if (this.selectedInput >= this.maxInput) this.selectedInput = -1
      let find = document.querySelectorAll(`.input-form`)[this.selectedInput + 1]
      this.selectElement(find)
    },

    handleArrowLeft() {
      if (this.selectedInput <= 0) this.selectedInput = document.querySelectorAll(`.input-form:not(:disabled)`).length
      let find = document.querySelectorAll(`.input-form:not(:disabled)`)[this.selectedInput - 1]
      this.selectElement(find)
    },

    handleArrowDown() {
      if (this.selectedInput + this.maxInputForm > this.maxInput) this.selectedInput = -(this.maxInput + 1 - this.selectedInput) + this.maxInputForm
      else this.selectedInput += this.maxInputForm
      let find = document.querySelectorAll(`.input-form:not(:disabled)`)[this.selectedInput]
      this.selectElement(find)
    },

    handleArrowUp() {
      if (this.selectedInput - this.maxInputForm < 0) this.selectedInput = this.maxInput + this.selectedInput + 1
      this.selectedInput -= this.maxInputForm
      let find = document.querySelectorAll(`.input-form:not(:disabled)`)[this.selectedInput]
      this.selectElement(find)
    },

    selectElement(el) {
      if (el) {
        if (el.querySelector('input')) el.querySelector('input').focus()
        else if (el.querySelector('select')) el.querySelector('select').focus()
        else if (el.querySelector('button')) el.querySelector('button').focus()
      }
    },

    init() {
      if (this.selectedFarm.ponds.length) {
        if (this.selectedFarm.blocks.length) this.selectedBlock = this.selectedFarm.blocks[0]

        this.resetForm()
      }
    },
    resetForm() {
      this.data = []
      this.times = []
      this.selectedDate = new Date()

      let times = ['07:00', '10:00', '13:00', '16:00', '19:00']

      for (let i = 0; i < times.length; i++) {
        this.times.push(new Date(this.$dateFormat(null, 'YYYY-MM-DD') + ' ' + times[i]))
      }

      for (let i = 0; i < this.filteredPondsInput.length; i++) {
        this.data.push({
          pond: this.filteredPondsInput[i],
          data_anco: [],
        })
      }

      let defaultAnco = []
      for (let i = 0; i < this.totalAnco; i++) {
        defaultAnco.push('Habis')
      }

      for (let i = 0; i < this.data.length; i++) {
        let item = this.data[i]

        for (let j = 0; j < this.times.length; j++) {
          item.data_anco.push(this._.cloneDeep(defaultAnco))
        }
      }
    },

    onChangePond(item) {
      if (item.pond) {
        item.data_anco = []
        for (let i = 0; i < item.pond.anco; i++) {
          item.data_anco.push('Habis')
        }
      }
    },

    deleteData(key) {
      this.times.splice(key, 1)
    },

    addData(key) {
      let defaultAnco = []

      for (let i = 0; i < this.totalAnco; i++) {
        defaultAnco.push('Habis')
      }

      for (let i = 0; i < this.selectedFarm.ponds.length; i++) {
        this.data[i].data_anco.splice(key + 1, 0, this._.cloneDeep(defaultAnco))
      }

      this.times.splice(key + 1, 0, null)
    },

    async handleSubmit() {
      this.$loading()

      let data = []
      let ponds = []

      data = this.filteredData.reduce((a, b) => {
        if (b.pond) {
          let isDataFilled = false
          for (let i = 0; i < b.data_anco.length; i++) {
            if (b.data_anco[i].find(e => e)) {
              isDataFilled = true
              break
            }
          }

          if (isDataFilled) {
            a.push(b)
            if (!ponds.find(f => f.pond_id === b.pond.pond_id)) ponds.push(b.pond)
          }
        }
        return a
      }, data)

      for (let i = 0; i < ponds.length; i++) {
        let stock = await this.$store.dispatch('farm/getActiveStock', ponds[i].pond_id)
        if (stock) ponds[i].stock = stock
      }

      let payload = []
      let timediff = new Date().getTimezoneOffset() * -1

      for (let i = 0; i < data.length; i++) {
        let item = data[i]
        let pond = ponds.find(e => e.pond_id === item.pond.pond_id)
        if (pond && pond.stock) {
          let stock = pond.stock

          for (let j = 0; j < this.times.length; j++) {
            let time = this.times[j]

            if (time) {
              let date = this.$timestamp(this.$dateFormat(this.selectedDate, 'YYYY-MM-DD') + ' ' + this.$timeFormat(time))

              payload.push({
                farm_id: this.farm_id,
                block_id: pond.block_id,
                stock_id: stock.stock_id,
                created_at: date,
                date: this.$startDay(date).valueOf(),
                data_anco: item.data_anco[j].map((e, key) => {
                  return {
                    anco_name: (key + 1) + "",
                    sisa: e
                  }
                }).slice(0, pond.anco),
                timediff,
              })
            }
          }
        }
      }

      await this.$store.dispatch('farm/addAnco', payload)

      this.$loading(false)
      this.resetForm()

      this.$router.push('/farm/' + this.selectedFarm.farm_id)
    },
  },
}
</script>

<style scoped lang="scss">
.card {
  width: 1200px !important;
  max-width: 100%;
}
</style>
